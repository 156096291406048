/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'keen-slider/keen-slider.min.css';

import { Carousel } from '@mantine/carousel';
import { Center } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import Image from 'next/image';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import * as React from 'react';

export default function ImageSection({
  sectionData,
  data,
}: {
  sectionData: any;
  data: any;
}) {
  // const [sliderRef] = useKeenSlider({ loop: true }, [
  //   (slider) => {
  //     let timeout: string | number | NodeJS.Timeout | undefined;
  //     let mouseOver = false;

  //     function clearNextTimeout() {
  //       clearTimeout(timeout);
  //     }

  //     function nextTimeout() {
  //       clearTimeout(timeout);
  //       if (mouseOver) return;
  //       timeout = setTimeout(() => {
  //         slider.next();
  //       }, 2000);
  //     }

  //     slider.on('created', () => {
  //       slider.container.addEventListener('mouseover', () => {
  //         mouseOver = true;
  //         clearNextTimeout();
  //       });
  //       slider.container.addEventListener('mouseout', () => {
  //         mouseOver = false;
  //         nextTimeout();
  //       });
  //       nextTimeout();
  //     });

  //     slider.on('dragStarted', clearNextTimeout);
  //     slider.on('animationEnded', nextTimeout);
  //     slider.on('updated', nextTimeout);
  //   },
  // ]);

  // console.log(sectionData);
  const autoplay = React.useRef(Autoplay({ delay: 2000 }));

  return (
    <div
      className={`grid w-full ${sectionData.id === data[0].id && '-mt-[16px]'}`}
    >
      {sectionData?.content?.length > 0 ? (
        <Carousel
          maw={'100%'}
          mx="auto"
          w={'100%'}
          // withIndicators
          height={'auto'}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          withControls={false}
        >
          {sectionData?.content?.map(
            (galleryData: any, i: any) =>
              galleryData.image && (
                <Carousel.Slide key={i}>
                  <a href={galleryData.url || '#'} className="cursor-pointer">
                    <div style={{ width: 'auto' }}>
                      <Center w={'100%'}>
                        <Image
                          className="h-auto w-full object-cover object-center"
                          src={galleryData.image.url}
                          alt="gallery"
                          width={400}
                          height={400}
                        />
                      </Center>
                    </div>
                  </a>
                </Carousel.Slide>
              ),
          )}
        </Carousel>
      ) : (
        // <div ref={sliderRef} className="keen-slider">
        // </div>
        <a
          href={sectionData?.content[0]?.url || '#'}
          className="cursor-pointer"
        >
          <div style={{ width: 'auto' }}>
            <Image
              className="w-auto object-cover object-center"
              src={sectionData?.content[0]?.image.url}
              alt="gallery"
              width={400}
              height={400}
            />
          </div>
        </a>
      )}
    </div>
  );
}

ImageSection.propTypes = {
  data: PropTypes.array.isRequired,
  sectionData: PropTypes.object.isRequired,
};
